import React from "react";


const RenderStars = (rating) => {
    if (typeof rating !== 'number' || rating < 0) {
      return null;
    }
  
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 >= 0.5;
    const emptyStars = Math.max(0, 5 - fullStars - (halfStar ? 1 : 0));
  
    return (
      <div className="rating-badge__stars">
        {Array(fullStars)
          .fill(0)
          .map((_, i) => (
            <svg key={i} aria-hidden="true" width="12" className="icon icon-star-rating" viewBox="0 0 12 11">
              <path
                d="M6 0v8.635L2.292 11 3.48 6.87 0 4.202l4.443-.187L6 0Zm0 0v8.635L9.708 11 8.52 6.87 12 4.202l-4.443-.187L6 0Z"
                fill="#1c1c1c"
              ></path>
            </svg>
          ))}
        {halfStar && (
          <svg aria-hidden="true" width="12" className="icon icon-star-rating" viewBox="0 0 12 11">
            <defs>
              <clipPath id="half-star">
                <rect x="0" y="0" width="6" height="11" />
              </clipPath>
            </defs>
            <path
              d="M6 0v8.635L2.292 11 3.48 6.87 0 4.202l4.443-.187L6 0Zm0 0v8.635L9.708 11 8.52 6.87 12 4.202l-4.443-.187L6 0Z"
              fill="#9A9A9A"
            />
            <path
              d="M6 0v8.635L2.292 11 3.48 6.87 0 4.202l4.443-.187L6 0Zm0 0v8.635L9.708 11 8.52 6.87 12 4.202l-4.443-.187L6 0Z"
              fill="#1c1c1c"
              clipPath="url(#half-star)"
            />
          </svg>
        )}
        {Array(emptyStars)
          .fill(0)
          .map((_, i) => (
            <svg
              key={i + fullStars + (halfStar ? 1 : 0)}
              aria-hidden="true"
              width="12"
              className="icon icon-star-rating"
              viewBox="0 0 12 11"
            >
              <path
                d="M6 0v8.635L2.292 11 3.48 6.87 0 4.202l4.443-.187L6 0Zm0 0v8.635L9.708 11 8.52 6.87 12 4.202l-4.443-.187L6 0Z"
                fill="#9A9A9A"
              />
            </svg>
          ))}
      </div>
    );
  };



export default RenderStars