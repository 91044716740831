import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { Stack } from '@mui/material';

// export default function CustomizedSnackbars() {
const CustomizedSnackbars = (prop) => {
    
  const [open, setOpen] = React.useState(false);
  const {errorResponse} = prop

  React.useEffect(() => {
    console.log("in the useeffect response", errorResponse);
    if(errorResponse) {
        console.log("in the error response", errorResponse);
      setOpen(true);
    }
  }, [errorResponse]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Stack direction="row" justifyContent="flex-end">
      {/* <Button onClick={handleClick}>Open Snackbar</Button> */}
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
       {errorResponse}
        </Alert>
      </Snackbar>
    </Stack>
  );
}

export default CustomizedSnackbars

