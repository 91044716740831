import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
  Box,
  Toolbar,
  Typography,
  Collapse,
  Divider,
  Switch,
  Slider,
} from "@mui/material";
import {
  ExpandMoreOutlined,
  ExpandLessOutlined,
  Category,
} from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { baseUrl } from "../../core";
import FilterDrawer from "../../components/filter-drawer/filter-drawer";
import DisplayProducts from "../../components/display-products/display-products";
import FeaturedSlider from "../../components/slider-featured/slider-featured";
import getCookie from "../../utlilityFunctions/getAcessToken";
import "./collection.css";

const Collection = () => {
  const params = useParams();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandedPrice, setIsExpandedPrice] = useState(false);
  const [priceRange, setPriceRange] = useState([0, 5000]);
  const productShowRef = useRef(null);
  const navBarRef = useRef(null);
  const sliderRef = useRef(null);
  const [isFixed, setIsFixed] = useState(false);
  const filterDivRef = useRef(null);
  const [isFilterFixed, setIsFilterFixed] = useState(false);
  const [filterProducts, setFilterProducts] = useState([]);
  const [category, setCategory] = useState(null);
  const [brand, setBrand] = useState(null);

  useEffect(() => {
    if (["man", "women", "kid"].includes(params.collectionName)) {
      setCategory(params.collectionName);
      setBrand(null);
    } else if (
      ["mango", "zara", "denim", "springField"].includes(params.collectionName)
    ) {
      setBrand(params.collectionName);
      setCategory(null);
    }
    else if (params.collectionName === "newReleases") {
      console.log("new releases condion");
      setCategory(null);
      setBrand(null);

    

    }
  }, [params.collectionName]);
  useEffect(() => {
    if (params.collectionName !== "newReleases") {
      console.log("hello new releases condion");
      if (!(category === null && brand === null)) {
        getProduct();
      }
    }
  }, [category, brand, params.collectionName]);
  
  useEffect(() => {
    if (category === null && brand === null && params.collectionName === "newReleases") {
      getProduct();
    }
  }, [category, brand, params.collectionName]);
  // useEffect(() => {
  //   if (params.collectionName === "newReleases") {
  //     console.log("hello new releases condion in second");
  //     getProduct();
  //   }
  // }, [params.collectionName]);

  useEffect(() => {
    const TOLERANCE = 100;

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    const handleScroll = () => {
      const navBarHeight = navBarRef.current
        ? navBarRef.current.offsetHeight
        : 0;
      const sliderTop = sliderRef.current
        ? sliderRef.current.getBoundingClientRect().top
        : 0;
      const sliderHeight = sliderRef.current
        ? sliderRef.current.offsetHeight
        : 0;
      const halfSliderVisible =
        sliderTop <= window.innerHeight - sliderHeight / 13;

      // console.log(
      //   "window.innerWidth",
      //   window.innerWidth,
      //   "navBarHeight",
      //   navBarHeight,
      //   "sliderTop",
      //   sliderTop,
      //   "halfSliderVisible",
      //   halfSliderVisible,
      //   "window.scrollY",
      //   window.scrollY
      // );

      if (window.scrollY > 37) {
        if (sliderTop >= navBarHeight + TOLERANCE) {
          setIsFixed(true);
        } else if (sliderTop <= navBarHeight) {
          setIsFixed(false);
        }
      } else {
        setIsFixed(false);
      }

      if (isFixed && window.scrollY > 37) {
        setIsFilterFixed(true);
      } else {
        setIsFilterFixed(false);
      }
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isFixed]);

  // console.log("the filter fixed0", isFilterFixed);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleToggleAvailability = () => {
    setIsExpanded(!isExpanded);
  };

  const handleTogglePrice = () => {
    setIsExpandedPrice(!isExpandedPrice);
  };

  const handlePriceChange = (event, newValue) => {
    setPriceRange(newValue);
  };
  const getProduct = async (
    availability = null,
    minPrice = null,
    maxPrice = null
    // category = null,
    // brand = null
  ) => {
    try {
      const token = getCookie("accessToken");


       console.log("the availability", availability, "the min price", minPrice, "the max price", maxPrice, "the category", category, "the brand", brand);
      const response = await axios.get(`${baseUrl}/v1/product`, {
        params: {
          availability,
          minPrice,
          maxPrice,
          category,
          brand,
        },
        headers: {
          // Authorization: `Bearer ${token}`,

          "Content-Type": "application/json",
        },
      });
      setFilterProducts(response.data.products);
      console.log("the product ", response.data.products);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getProductFilter = (availability, minPrice, maxPrice = null) => {
    getProduct(availability, minPrice, maxPrice);
  };

  return (
    <>
      <Box className="collection-title" ref={navBarRef}>
        <p>
          {params.collectionName === "springField" && "SPRING FIELD"}
          {params.collectionName === "newReleases" && "NEW RELEASES"}
          {!["springField", "newReleases"].includes(params.collectionName) &&
            params.collectionName}
        </p>
      </Box>

      <Box sx={{ display: "flex" }}>
        <FilterDrawer
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
          isExpanded={isExpanded}
          handleToggleAvailability={handleToggleAvailability}
          isExpandedPrice={isExpandedPrice}
          handleTogglePrice={handleTogglePrice}
          priceRange={priceRange}
          handlePriceChange={handlePriceChange}
          getProductFilter={getProductFilter}
        />
      </Box>

      <Box>
        <Box
          className="collection-count"
          style={{
            position: isFixed ? "fixed" : "relative",
            top: isFixed ? "80px" : "0",
            width: "100%",
          }}
        >
          <Typography
            className="collection-count-div"
            ref={filterDivRef}
            sx={{
              letterSpacing: "0.1em",
              fontSize: "0.7rem",
              fontFamily: "Montserrat, sans-serif",
              display: { xs: "none", sm: "block", md: "block" },
            }}
          >
            {filterProducts.length} PRODUCTS
          </Typography>

          <Toolbar
            className="filter-toolbar"
            style={{ display: windowWidth >= 1000 ? "none" : "block" }}
            onClick={handleDrawerToggle}
          >
            <div className="filter-button">
              <p>FILTER</p>
            </div>
          </Toolbar>
        </Box>
        <div className="main-collection-div">
          <Box
            className="filter-div"
            style={{
              display: windowWidth <= 1000 ? "none" : "block",
              position: isFilterFixed ? "fixed" : "relative",
              top: isFilterFixed ? "120px" : "0",
              width: "300px",
              left: isFilterFixed ? "0" : "initial",
            }}
          >
            <div
              className="drawer-section"
              style={{ marginTop: "50px" }}
              onClick={handleToggleAvailability}
            >
              <p>AVAILABILITY</p>
              {isExpanded ? (
                <ExpandLessOutlined
                  fontSize="small"
                  style={{ marginRight: "20px" }}
                />
              ) : (
                <ExpandMoreOutlined
                  fontSize="small"
                  style={{ marginRight: "20px" }}
                />
              )}
            </div>

            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
              <div className="switch-container">
                <Switch
                  size="small"
                  defaultChecked={false}
                  onClick={(event) =>
                    getProductFilter(event.target.checked, null, null)
                  }
                  sx={{
                    width: 45,
                    "& .MuiSwitch-track": {
                      backgroundColor: "black",
                      "&:hover": { backgroundColor: "black" },
                    },
                    "& .MuiSwitch-thumb": {
                      color: "white",
                      "&:hover": { backgroundColor: "white" },
                    },
                  }}
                />
                <p>In stock only</p>
              </div>
            </Collapse>

            <div style={{ marginTop: 22 }}>
              <Divider style={{ width: "92%", margin: "0 auto" }} />
            </div>

            <div className="drawer-section" onClick={handleTogglePrice}>
              <p>Price</p>
              {isExpandedPrice ? (
                <ExpandLessOutlined
                  fontSize="small"
                  style={{ marginRight: "20px" }}
                />
              ) : (
                <ExpandMoreOutlined
                  fontSize="small"
                  style={{ marginRight: "20px" }}
                />
              )}
            </div>

            <Collapse in={isExpandedPrice} timeout="auto" unmountOnExit>
              <div style={{ padding: "5px 22px" }}>
                <Slider
                  value={priceRange}
                  onChange={(event, newValue) => {
                    handlePriceChange(event, newValue);
                  }}
                  onChangeCommitted={(event, newValue) => {
                    getProductFilter(null, newValue[0], newValue[1]);
                  }}
                  valueLabelDisplay="auto"
                  min={0}
                  max={5000}
                  sx={{
                    "& .MuiSlider-track": {
                      backgroundColor: "black",
                      color: "black",
                    },
                    "& .MuiSlider-rail": { backgroundColor: "gray" },
                    "& .MuiSlider-thumb": {
                      color: "black",
                      width: 13,
                      height: 13,
                    },
                  }}
                />
                <div className="price-display">
                  <div>RsPKR: {priceRange[0]}</div>
                  <div>RsPKR: {priceRange[1]}</div>
                </div>
              </div>
            </Collapse>
          </Box>

          <Box
            className="product-show-div"
            ref={productShowRef}
            style={{
              width:
                windowWidth <= 1000
                  ? "100%"
                  : isFilterFixed
                  ? "calc(100% - 300px)"
                  : "100%",
              marginLeft: windowWidth > 1000 && isFilterFixed ? "300px" : "0",
              minHeight: "500px",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {filterProducts.length === 0 && (
              <p
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  padding: "10px 30px",
                }}
              >
                No products found
              </p>
            )}
            <DisplayProducts filterProducts={filterProducts} />
          </Box>
        </div>
      </Box>

      <Box className="slider-box" ref={sliderRef}>
        <div className="slider-container-recently-viewed">
          <div className="slider-recently-viewed-heading">
            <p>RECENTLY VIEWED</p>
          </div>

          <FeaturedSlider />
        </div>
      </Box>
    </>
  );
};

export default Collection;
