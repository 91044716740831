import React, { useState } from "react";
import { Drawer, List, ListItem, ListItemText, Divider } from "@mui/material";
import {
  CloseOutlined,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import "./nav-bar-drawer.css";

const drawerWidth = 340;

const NavbarDrawer = ({ mobileOpen, handleDrawerToggle }) => {
  const [drawerState, setDrawerState] = useState("main");
  const navigate = useNavigate();

  const toggleDrawer = (state) => {
    setDrawerState(state);
  };

  const handleCloseDrawer = () => {
    handleDrawerToggle();
    setDrawerState("main");
  };

  // Main Drawer content
  const mainDrawer = (
    <div
      style={{
        marginTop: 0,
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        backgroundColor: "#EFEFEF",
      }}
    >
      <div className="heading-drawer-nav">
        <CloseOutlined
          style={{ marginRight: "20px" }}
          onClick={handleCloseDrawer}
        />
      </div>
      <List>
        <ListItem
          button
          onClick={() => {
            handleCloseDrawer();
            navigate("/");
          }}
        >
          <ListItemText primary="Home" />
        </ListItem>

        <Divider style={{ width: "90%", margin: "0 auto" }} />
        <ListItem
          button
          onClick={() => {
            handleCloseDrawer();
            navigate("/collection/newRelease");
          }}
        >
          <ListItemText primary="New Release" />
        </ListItem>
        <Divider style={{ width: "90%", margin: "0 auto" }} />

        <ListItem button onClick={() => toggleDrawer("brand")}>
          <span
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <ListItemText primary="Shop By Brand" />
            <KeyboardArrowRight />
          </span>
        </ListItem>
        <Divider style={{ width: "90%", margin: "0 auto" }} />
        <ListItem button onClick={() => toggleDrawer("collection")}>
          <span
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <ListItemText primary="Shop Clothing" />
            <KeyboardArrowRight />
          </span>
        </ListItem>
        <Divider style={{ width: "90%", margin: "0 auto" }} />
      </List>
    </div>
  );

  // Brand Drawer content
  const brandDrawer = (
    <div
      style={{
        marginTop: 0,
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        backgroundColor: "#EFEFEF",
      }}
    >
      <div className="heading-drawer-nav">
        <CloseOutlined
          style={{ marginRight: "20px" }}
          onClick={handleCloseDrawer}
        />
      </div>
      <List>
        <ListItem
          button
          onClick={() => toggleDrawer("main")}
          style={{ marginLeft: "-5px" }}
        >
          <KeyboardArrowLeft style={{ fontWeight: "100" }} />
          <span>
            <ListItemText primary="Shop By Brand" />
          </span>
        </ListItem>
        <Divider style={{ width: "90%", margin: "0 auto" }} />
        <ListItem
          button
          onClick={() => {
            handleCloseDrawer();
            navigate("/collection/mango");
          }}
        >
          <span>
            <ListItemText primary="Mango" />
          </span>
        </ListItem>
        <Divider style={{ width: "90%", margin: "0 auto" }} />
        <ListItem
          button
          onClick={() => {
            handleCloseDrawer();
            navigate("/collection/denim");
          }}
        >
          <span>
            <ListItemText primary="Denim" />
          </span>
        </ListItem>
        <Divider style={{ width: "90%", margin: "0 auto" }} />
        <ListItem
          button
          onClick={() => {
            handleCloseDrawer();
            navigate("/collection/zara");
          }}
        >
          <span>
            <ListItemText primary="Zara" />
          </span>
        </ListItem>
        <Divider style={{ width: "90%", margin: "0 auto" }} />
        <ListItem
          button
          onClick={() => {
            handleCloseDrawer();
            navigate("/collection/saya");
          }}
        >
          <span>
            <ListItemText primary="Saya" />
          </span>
        </ListItem>
        <Divider style={{ width: "90%", margin: "0 auto" }} />
      </List>
    </div>
  );

  // Collection Drawer content
  const collectionDrawer = (
    <div
      style={{
        marginTop: 0,
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        backgroundColor: "#EFEFEF",
      }}
    >
      <div className="heading-drawer-nav">
        <CloseOutlined
          style={{ marginRight: "20px" }}
          onClick={handleCloseDrawer}
        />
      </div>
      <List>
        <ListItem
          button
          onClick={() => toggleDrawer("main")}
          style={{ marginLeft: "-5px" }}
        >
          <KeyboardArrowLeft style={{ fontWeight: "100" }} />
          <span>
            <ListItemText primary="Shop By Clothing" />
          </span>
        </ListItem>
        <Divider style={{ width: "90%", margin: "0 auto" }} />
        <ListItem
          button
          onClick={() => {
            handleCloseDrawer();
            navigate("/collection/man");
          }}
        >
          <span>
            <ListItemText primary="Man" />
          </span>
        </ListItem>
        <Divider style={{ width: "90%", margin: "0 auto" }} />
        <ListItem
          button
          onClick={() => {
            handleCloseDrawer();
            navigate("/collection/women");
          }}
        >
          <span>
            <ListItemText primary="Women" />
          </span>
        </ListItem>
        <Divider style={{ width: "90%", margin: "0 auto" }} />
        <ListItem
          button
          onClick={() => {
            handleCloseDrawer();
            navigate("/collection/kid");
          }}
        >
          <span>
            <ListItemText primary="Kid" />
          </span>
        </ListItem>
        <Divider style={{ width: "90%", margin: "0 auto" }} />
      </List>
    </div>
  );

  const renderDrawer = () => {
    if (drawerState === "brand") return brandDrawer;
    if (drawerState === "collection") return collectionDrawer;
    return mainDrawer;
  };

  return (
    <Drawer
      variant="temporary"
      open={mobileOpen}
      onClose={handleCloseDrawer}
      sx={{
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          backgroundColor: "white",
          zIndex: 1300,
        },
      }}
    >
      {renderDrawer()}
    </Drawer>
  );
};

export default NavbarDrawer;
