import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: null, // Initially set to null
        isLogin: false, // Set a default false login status
        role: null, // To store user role
    },
    reducers: {
        userLogin: (state, action) => {
            // Print the payload and existing state for debugging
            console.log("Action Payload:", action.payload.isLogin);
            console.log("Action Payload user:", action.payload.user);
            console.log("Existing State:", state.user);




            if (action.payload && action.payload.user) {
                state.user = action.payload.user; // Store the user info
                state.isLogin = true; // Update login status
                state.role = action.payload.user.role; // Store the user's role
            }
            console.log("New State:", state.isLogin);
            console.log("New State user:", state.user);
            console.log("New State role:", state.role);
        },
    },
});

export const { userLogin } = userSlice.actions;
export default userSlice.reducer;