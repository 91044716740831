import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Typography,
  InputLabel,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import { useSelector } from "react-redux";
import "./profile.css";

const Profile = () => {
  const userProfile = useSelector((state) => state.user.user);

  return (
    <>
      <div className="profile-div">
        <div className="profile-info">
          <p className="profile-heading">Profile</p>
          <div className="info">
            <div>
              <p>Name</p>
              <span>Muhammad Wayl</span>
            </div>
            <div>
              <p>Email</p>
              <span>mwayluddin@gmail.com</span>
            </div>
            <div>
              <p>Contact No</p>
              <span>03182158276</span>
            </div>
            <div>
              <p>country</p>
              <span>Pakistan</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Profile;
