import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  RadioGroup,
  List,
  FormControlLabel,
  Radio,
  TextField,
} from "@mui/material";

import "./checkout.css";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

const phoneRegExp = /^[0-9]{10,15}$/;
const validationSchema = Yup.object().shape({
  contact: Yup.string()
    .test(
      "email-or-phone",
      "Must be a valid email or phone number",
      function (value) {
        const isValidEmail = Yup.string().email().isValidSync(value);
        const isValidPhone = phoneRegExp.test(value);
        return isValidEmail || isValidPhone;
      }
    )
    .required("This field is required"),
  countryName: Yup.string().required("Country is required"),

  firstName: Yup.string()
    // .min(2, 'Name is too short!')
    .max(50, "Name is too long!")
    .required("Name is required"),
  lastName: Yup.string()
    // .min(2, 'Name is too short!')
    .max(50, "Name is too long!")
    .required("Name is required"),
  address: Yup.string()
    // .min(2, 'Name is too short!')
    .max(70, "address is too long!")
    .required("Address is required"),
  city: Yup.string()
    // .min(2, 'Name is too short!')
    .max(50, "City is too long!")
    .required("City is required"),
  phoneNumber: Yup.string()
    .test("phone-number", "Phone number is not valid", function (value) {
      return phoneRegExp.test(value);
    })
    .required("Phone number is required"),
  shippingMethod: Yup.string().required("Please select a shipping method"),
  cashOnDelivery: Yup.string().required("Please select a cash method"),
});

const initialValues = {
  contact: "",
  countryName: "",
  firstName: "",
  lastName: "",
  address: "",
  city: "",
  phoneNumber: "",
  shippingMethod: "free",
  cashOnDelivery: "cod",
};

const Checkout = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const cartItems = useSelector((state) => state.cart.items);
  const descriptionRef = useRef(null);
  const [isDescriptionFixed, setIsDescriptionFixed] = useState(false);
  const [coupanCode, setCoupanCode] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    const handleScroll = () => {
      const descriptionTop = descriptionRef.current
        ? descriptionRef.current.getBoundingClientRect().top
        : 0;
      const descriptionHeight = descriptionRef.current
        ? descriptionRef.current.offsetHeight
        : 0;
      const descriptionVisible =
        descriptionTop <= window.innerHeight - descriptionHeight / 13;
      console.log("the scroll", window.scrollY);

      if (window.scrollY > 96 && windowWidth > 1100) {
        setIsDescriptionFixed(true);
      } else {
        setIsDescriptionFixed(false);
      }
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleCoupanSubmit = () => {
    // Handle the coupan code submission logic
    console.log("coupan code", coupanCode);
  };

  const returnHome = () => {
    navigate("/");
  };

  return (
    <>
      <Box className="checkout-container">
        <Box className="checkout-header">
          <Box className="checkout-header-title" onClick={returnHome}>
            <img
              src={`https://storage.googleapis.com/blog-website-pic-bucket.appspot.com/profileHackathon/postImg-1728384181725-logo-pic.png?GoogleAccessId=firebase-adminsdk-viejr%40blog-website-pic-bucket.iam.gserviceaccount.com&Expires=2530378800&Signature=mHra4DsXsuZYF%2FSloNbpDQLUqgNopfcSMnWl5t%2Ff%2BW3VJg8EqQhhSky0CY%2FzCnaqTnp6xJe1clrs4yfzlGPaDvvKUQXxJLIxZJ%2BOxkNjlPgl7SlGg%2FEnP12ryEeabGb4eLzlVX0TFQXZmWwTbuD4R3PYrNA8tasDzk66hfZyKVPK4ndT1jqqcIVzvo12JsbHUNZzgRAmlJbPy%2BuqLcAlCffIcjVBDk%2BdjmOX2h75wzaDV1eo65AKeYRddrtlZXJc73BdL%2Bt5EGGFuIPN4xN9KRNK3dD0oVHSb4bK8WY2Hf75b5wFrig%2BHztrYMdLtsmbwxxGJ9i%2FkqaWeaDfAHjE6A%3D%3D`}
              alt="logo"
              className="logo-cart"
            />
          </Box>
        </Box>

        <Box className="checkout-content">
          {/* Left side div with greater width */}
          <Box
            className="left-side"
            style={{
              width:
                windowWidth <= 1100
                  ? "100%"
                  : isDescriptionFixed
                  ? "calc(100% - 45%)"
                  : "100%",
              marginLeft: windowWidth > 1100 && isDescriptionFixed ? "0" : "0",
            }}
          >
            <div
              className="user-info"
              style={{
                marginRight:
                  windowWidth > 1100 && isDescriptionFixed
                    ? "46%"
                    : windowWidth < 1100
                    ? "13%"
                    : "33px",
              }}
            >
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  console.log("Form data", values);
                }}
                validateOnChange={false}
                validateOnBlur={false}
              >
                {({ errors, touched, submitCount }) => (
                  <Form>
                    {/* Contact Section */}
                    <div className="contact-section">
                      <div className="contact-heading">
                        <p>Contact</p>
                        <span>Login</span>
                      </div>
                      <Field
                        name="contact"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            type="text"
                            id="contact"
                            label="Enter email or phone number"
                            fullWidth
                            error={submitCount > 0 && Boolean(errors.contact)}
                            helperText={submitCount > 0 && errors.contact}
                            variant="outlined"
                            className="contact-field"
                            sx={{ height: "45px" }}
                          />
                        )}
                      />
                    </div>

                    {/* Shipping Section */}
                    <div className="shipping-section">
                      <p>Shipping details</p>

                      <div className="country-field">
                        <Field
                          name="phoneNumber"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type="text"
                              id="countryName"
                              label="Country name"
                              fullWidth
                              error={
                                submitCount > 0 && Boolean(errors.countryName)
                              }
                              helperText={submitCount > 0 && errors.countryName}
                              variant="outlined"
                            />
                          )}
                        />
                      </div>

                      <div className="name-field">
                        <Field
                          name="firstName"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type="text"
                              id="firstName"
                              label="Enter first name"
                              error={
                                submitCount > 0 && Boolean(errors.firstName)
                              }
                              helperText={submitCount > 0 && errors.firstName}
                              variant="outlined"
                              sx={{ width: "48.5%" }}
                            />
                          )}
                        />
                        <Field
                          name="lastName"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type="text"
                              id="lastName"
                              label="Enter last name"
                              error={
                                submitCount > 0 && Boolean(errors.lastName)
                              }
                              helperText={submitCount > 0 && errors.lastName}
                              variant="outlined"
                              sx={{ width: "48.5%" }}
                            />
                          )}
                        />
                      </div>

                      <div className="address-field">
                        <Field
                          name="address"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type="text"
                              id="address"
                              label="Enter your address"
                              fullWidth
                              error={submitCount > 0 && Boolean(errors.address)}
                              helperText={submitCount > 0 && errors.address}
                              variant="outlined"
                            />
                          )}
                        />
                      </div>

                      <div className="city-field">
                        <Field
                          name="city"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type="text"
                              id="city"
                              label="Enter your city"
                              error={submitCount > 0 && Boolean(errors.address)}
                              helperText={submitCount > 0 && errors.address}
                              variant="outlined"
                              sx={{ width: "48.5%" }}
                            />
                          )}
                        />
                        <Field
                          name="city"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type="text"
                              id="postalCode"
                              label="Postal Code (Optional)"
                              variant="outlined"
                              sx={{ width: "48.5%" }}
                            />
                          )}
                        />
                      </div>

                      <div className="alternate-phone-field">
                        <Field
                          name="phoneNumber"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type="text"
                              id="phoneNumber"
                              label="Phone number for order updates"
                              fullWidth
                              error={submitCount > 0 && Boolean(errors.address)}
                              helperText={submitCount > 0 && errors.address}
                              variant="outlined"
                            />
                          )}
                        />
                      </div>
                    </div>

                    <div className="shipping-method">
                      <p className="heading-shipping">Shipping Method</p>
                      <div className="shipping-div">
                        <Field name="shippingMethod">
                          {({ field }) => (
                            <RadioGroup {...field} row>
                              <div
                                className={`radio-field-free ${
                                  field.value === "free" ? "selected" : ""
                                }`}
                              >
                                <FormControlLabel
                                  value="free"
                                  control={<Radio />}
                                />
                                <div className="free-section">
                                  <div>
                                    <p>Standard (4-7 working days)</p>
                                    <p>No extra fee and ships next day</p>
                                  </div>
                                  <div>FREE</div>
                                </div>
                              </div>

                              <div
                                className={`radio-field-express ${
                                  field.value === "express" ? "selected" : ""
                                }`}
                              >
                                <FormControlLabel
                                  value="express"
                                  control={<Radio />}
                                />
                                <div className="free-section">
                                  <div>
                                    <p>Express (1-3 working days)</p>
                                    <p>
                                      Ships Same Day *Orders Placed Before 5pm*
                                    </p>
                                  </div>
                                  <div>Rs 500.00</div>
                                </div>
                              </div>
                            </RadioGroup>
                          )}
                        </Field>
                      </div>
                    </div>

                    <div className="payment-method">
                      <p className="heading-payment">Payment Method</p>
                      <div className="payment-div">
                        <Field name="cashOnDelivery">
                          {({ field }) => (
                            <RadioGroup {...field} row>
                              <div
                                className={`payment-cash-cod ${
                                  field.value === "cod" ? "selected" : ""
                                }`}
                              >
                                <FormControlLabel
                                  value="cod"
                                  control={<Radio />}
                                />
                                <div className="cash-section">
                                  <div>
                                    <p>Cash on Delivery (COD)</p>
                                  </div>
                                  <div></div>
                                </div>
                              </div>
                            </RadioGroup>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className="submit-button-checkout">
                      <Button
                        type="submit"
                        variant="contained"
                        className="pay-button"
                      >
                        Confirm Order
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Box>

          {/* Right side div */}
          <Box
            className="right-side"
            style={{
              position:
                windowWidth <= 1100
                  ? "relative"
                  : isDescriptionFixed
                  ? "fixed"
                  : "sticky",
              top: isDescriptionFixed ? "0" : "0",
              width:
                windowWidth <= 1100
                  ? "100%"
                  : isDescriptionFixed
                  ? "44%"
                  : "44%",
              height: "100vh",
              right: isDescriptionFixed ? "0" : "initial",
              transition: "width 0.3s ease",
            }}
          >
            {/* Content for the right side */}
            <div className="cart-items">
              <p className="order-summary">Order Summary</p>
              <div className="cart-items-container-checkout">
                {cartItems.length > 0 ? (
                  <List>
                    {cartItems.map((item, index) => (
                      <div key={index}>
                        {/* Product Details */}
                        <div className="cart-item-details-checkout">
                          <img
                            src={item.image}
                            className="cart-item-image-checkout"
                          />
                          <div className="cart-item-info">
                            <div className="item-name-checkout">
                              {item.name}
                            </div>
                            <div className="item-size-checkout">{"normal"}</div>
                          </div>
                          <div className="item-price-checkout">
                            {item.price} PKR
                          </div>
                        </div>
                      </div>
                    ))}
                  </List>
                ) : (
                  <div className="empty-cart-message-checkout">
                    Your cart is empty
                  </div>
                )}
              </div>

              <div className="cart-discount-coupan">
                <form onSubmit={handleCoupanSubmit}>
                  <input
                    type="text"
                    value={coupanCode}
                    onChange={(e) => setCoupanCode(e.target.value)}
                    placeholder="Enter coupan code"
                    className="cart-discount-input"
                  />
                  <button type="submit" className="cart-discount-button">
                    Apply
                  </button>
                </form>
              </div>
              <div className="cart-total-checkout">
                <div className="sub-total">
                  <span>Subtotal</span>
                  <span>Rs 1200</span>
                </div>
                <div className="shipping">
                  <span>Shipping</span>
                  <span>Free</span>
                </div>
              </div>
              <div>
                <div className="total">
                  <span>Total</span>
                  <span>
                    <span className="currency-define">PKR </span> Rs 1200
                  </span>
                </div>
              </div>
              <div className="submit-button-cart">
                <Button
                  type="submit"
                  variant="contained"
                  className="pay-button"
                >
                  Confirm Order
                </Button>
              </div>
            </div>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Checkout;
