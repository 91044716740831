import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import ContextProvider from "./context/context";
import { Provider } from "react-redux";
import store from "../src/redux/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render( <
    Provider store = { store } >
    <
    React.StrictMode >

    { /* here we wrap our whole app with context provider from this in our app some other function is come  */ } { " " } <
    ContextProvider >
    <
    BrowserRouter >
    <
    App / >
    <
    /BrowserRouter> <
    /ContextProvider> <
    /React.StrictMode> <
    /Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();