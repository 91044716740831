import React, { useEffect, useContext, useState } from "react";

import "./App.css";
import axios from "axios";
import { Router, Routes, Route, Link, Navigate,useLocation } from "react-router-dom";

import Home from "./pages/home/home";
import Product from "./pages/product/product";
import Collection from "./pages/collection/collection";
import MainNavbar from "./components/main-nav-bar/main-nav-bar"
import Checkout from "./pages/checkout/checkout";
import Login from './pages/login/login';
import Otp from './pages/otp/otp'

import Profile from './pages/profile/profile'
import ScrollToTop from "./components/scroll-to-top/scroll-to-top";

import { Box, BottomNavigationAction,BottomNavigation,Typography } from '@mui/material';
import { WhatsApp , Email, LocationOn, Phone, Facebook, Twitter, YouTube } from '@mui/icons-material';
import { RiWhatsappFill } from "react-icons/ri";


import { GlobalContext } from "./context/context";
// import { baseUrl } from "../core";
import { baseUrl } from "./core";

function App() {
  //  const [isLogin, setIsLogin]=useState(false)
  const { state, dispatch } = useContext(GlobalContext);
  const location = useLocation();
  console.log("the location path",location.pathname); 

  // const [isFixed, setIsFixed] = useState(false);

  // useEffect(() => {
  //   axios.interceptors.request.use(
  //     function (config) {
  //       config.withCredentials = true;
  //       return config;
  //     },
  //     function (error) {
  //       return Promise.reject(error);
  //     }
  //   );
  // }, []);

  // useEffect(() => {
  //   const checkUserLoginStatus = async () => {
  //     console.log("Checking user login status");
  //     try {
  //       const response = await axios.get(`${baseUrl}/api/v1/profile`, {
  //         withCredentials: true,
  //       });
  //       // setIsLogin(true);
  //       dispatch({
  //         type: "USER_LOGIN",
  //         payload: response.data.data,
  //       });

  //       // console.log("where is hhh "+response.data.);
  //     } catch (error) {
  //       console.log("error is come in app use effect ", error);
  //       dispatch({
  //         type: "USER_LOGOUT",
  //       });
  //       // setIsLogin(false);
  //     }
  //   };

  //   checkUserLoginStatus();
  // }, []);

  const logoutHandler = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/v1/logout`,
        {},
        {
          withCredentials: true,
        }
      );

      dispatch({
        type: "USER_LOGOUT",
      });
    } catch (err) {
      console.log("error in logoutHandler" + err);
    }
  };

  


  const openWhatsApp = () => {
    window.open(`https://wa.me/03242002300?text=Hi%20I’m%20interested%20in%20your%20products!`, '_blank');
  };

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const announcementBarHeight = document.querySelector('.announcement-bar').offsetHeight;
  //     if (window.scrollY > announcementBarHeight) {
  //       setIsFixed(true);
  //     } else {
  //       setIsFixed(false);
  //     }
  //   };

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  // state.isLogin = true;
  return (
    <>
      <div className="page">
      
          <>
          {(location.pathname !== '/checkout' && location.pathname !== '/login' && location.pathname !== '/otp' && location.pathname !== '/signup') && (
        <Box className="announcement-bar" component="section">
          FREE SHIPPING + EASY RETURN AND EXCHANGE
        </Box>
      )}
          
            {(location.pathname !== '/checkout' && location.pathname !== '/login' && location.pathname !== '/otp' && location.pathname !== '/signup') && (
  <MainNavbar />
)}
          
           <ScrollToTop />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/product/:productId" element={<Product />} />
              <Route path="/collection/:collectionName" element={<Collection />} />
              <Route path="/checkout" element={<Checkout />} />
              {/* <Route path="/signup" element={<Signup />} /> */}
              <Route path="/login" element={<Login />} />
              <Route path="/otp" element={<Otp />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="*" element={<Navigate to="/" replace={true} />} />
            </Routes>
            
      
          </>

          {( location.pathname !== '/login' && location.pathname !== '/otp' && location.pathname !== '/signup') && (
   <div className="whatsapp-button" style={{ position: 'fixed', bottom: 20, right: 40, zIndex: 999, cursor: 'pointer' }} onClick={openWhatsApp}>
   <WhatsApp style={{ fontSize: 50, color: '#00A884' }} />
 </div>
)}

        

          {(location.pathname !== '/checkout' && location.pathname !== '/login' && location.pathname !== '/otp' && location.pathname !== '/signup' && location.pathname !== '/profile') && 
          (<footer class="footer">
          <div className="footer-box">
      <Typography variant="p" className="footer-title">WOKEGARMENTS</Typography>
     
      <Box className="footer-box-options">
        <Box className="footer-option">
          <LocationOn className="footer-icon" />
          <a href="#">
          <Typography variant="p">Karachi</Typography>
          </a>
        </Box>
        <Box className="footer-option">
          <Email className="footer-icon" />
          <a href="#">
          <Typography variant="p">wokegarments@gmail.com</Typography>
          </a>
        </Box>
        <Box className="footer-option">
          <Phone className="footer-icon" />
          <a href="#">
          <Typography variant="p">Whatsapp: 03242002300</Typography>
          </a>
        </Box>
      </Box>
    </div>

  <div class="footer-box">
     <Typography variant="p" className="footer-title">COLLECTIONS</Typography>
     <Box className="footer-box-options">
     {/* <a href="#"> */}
     <Typography variant="p">
     <Link to={`/collection/man`} style={{ textDecoration: 'none' }}>
      Man
      </Link>
      </Typography>
     {/* </a> */}

  <Typography variant="p">
  <Link to={`/collection/women`} style={{ textDecoration: 'none' }}>
    Women
    </Link>
    </Typography>
   

 
  <Typography variant="p">
  <Link to={`/collection/kid`} style={{ textDecoration: 'none' }}>
    Kids
    </Link>
    </Typography>

  </Box>
  </div>
  <div class="footer-box">
     <Typography variant="p" className="footer-title">QUICK LINKS</Typography>
     <Box className="footer-box-options">
     
     <Typography variant="p">
     <Link to={`/`} style={{ textDecoration: 'none' }}>
      Home</Link></Typography>
     
     
  <Typography variant="p">
  <Link to={`/collection/newReleases`} style={{ textDecoration: 'none' }}>
    New Releases</Link></Typography>
  
  
  <Typography variant="p">
  <Link to={`/collection/man`} style={{ textDecoration: 'none' }}>
    Shop Clothing</Link></Typography>
  
  
  <Typography variant="p">
  <Link to={`/collection/newReleases`} style={{ textDecoration: 'none' }}>
    Shop By Brand</Link></Typography>

  </Box>
  </div>
  <div class="footer-box">
     <Typography variant="p" className="footer-title">FOLLOW US</Typography>
     <Box sx={{backgroundColor: "transparent",marginTop: "15px", marginLeft: "-10px"}}>
     <a href="#">
        <Facebook sx={{ fontSize: 25,  color: "#FFFFFF", margin: '0 10px' }} />
        </a>
        <a href="#">
        <Twitter sx={{ fontSize: 25,  color: "#FFFFFF", margin: '0 10px' }} />
        </a>
        <a href="#">
        <YouTube sx={{ fontSize: 25,  color: "#FFFFFF", margin: '0 10px' }} />
        </a>
    </Box>
  </div>

</footer>)
}
{(location.pathname !== '/checkout' && location.pathname !== '/login' && location.pathname !== '/otp' && location.pathname !== '/signup' && location.pathname !== '/profile') && 
(<Box className="footer-rights-bar" component="section">
© 2024 Woke Garments. All rights reserved.
    </Box>)
}

      

        {/* splash screen */}
        {state.isLogin === null ? <div></div> : null}
      </div>
    </>
  );
}
export default App;

