import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import {
  Box,
  BottomNavigationAction,
  BottomNavigation,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  PersonOutlineOutlined,
  Search,
  LocalMallOutlined,
  Menu as MenuIcon,
} from "@mui/icons-material";
import NavbarDrawer from "../nav-bar-drawer/nav-bar-drawer";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AddToCartDrawer from "../../components/add-to-cart/add-to-cart";
import "./main-nav-bar.css";

const MainNavbar = () => {
  const userLoginInfo = useSelector((state) => state.user)
  const [isFixed, setIsFixed] = useState(false);
  const [value, setValue] = React.useState(0);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [cartOpen, setCartOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [brandAnchorEl, setBrandAnchorEl] = React.useState(null);
  const [clothingAnchorEl, setClothingAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const openBrand = Boolean(brandAnchorEl);
  const openClothing = Boolean(clothingAnchorEl);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const announcementBarHeight =
        document.querySelector(".announcement-bar").offsetHeight;
      if (window.scrollY > announcementBarHeight) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleCartToggle = () => {
    setCartOpen(!cartOpen);
  };

  const handleProfileMenuOpen = (event) => {
    console.log("the profile event ",event.currentTarget);
    if(userLoginInfo.isLogin === true){
      setAnchorEl(event.currentTarget);
    }
    else{
      navigate('/login')
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    console.log("Logout clicked");
    handleMenuClose();
  };

  const handleProfile = () => {
    console.log("Profile clicked");
    handleMenuClose();
  };

  const handleClickBrandMenu = (event) => {
    setBrandAnchorEl(event.currentTarget);
  };

  const handleCloseBrandMenu = () => {
    setBrandAnchorEl(null);
  };

  const handleClickClothingMenu = (event) => {
    setClothingAnchorEl(event.currentTarget);
  };

  const handleCloseClothingMenu = () => {
    setClothingAnchorEl(null);
  };

  const handleReRoute = (value) => {
    console.log(value);
    navigate(`/collection/${value}`);
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <AddToCartDrawer
          mobileOpen={cartOpen}
          handleDrawerToggle={handleCartToggle}
        />
      </Box>

      <Box sx={{ display: "flex" }}>
        <NavbarDrawer
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
      </Box>
      <div className={`header site-header ${isFixed ? "fixed" : ""}`}>
        <div className="header-container">
          <div className="filter-toolbar-nav-bar-div">
            <Toolbar onClick={handleDrawerToggle}>
              <IconButton color="inherit" aria-label="open drawer" edge="end">
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </div>

          <img
            src={`https://storage.googleapis.com/blog-website-pic-bucket.appspot.com/profileHackathon/postImg-1728384181725-logo-pic.png?GoogleAccessId=firebase-adminsdk-viejr%40blog-website-pic-bucket.iam.gserviceaccount.com&Expires=2530378800&Signature=mHra4DsXsuZYF%2FSloNbpDQLUqgNopfcSMnWl5t%2Ff%2BW3VJg8EqQhhSky0CY%2FzCnaqTnp6xJe1clrs4yfzlGPaDvvKUQXxJLIxZJ%2BOxkNjlPgl7SlGg%2FEnP12ryEeabGb4eLzlVX0TFQXZmWwTbuD4R3PYrNA8tasDzk66hfZyKVPK4ndT1jqqcIVzvo12JsbHUNZzgRAmlJbPy%2BuqLcAlCffIcjVBDk%2BdjmOX2h75wzaDV1eo65AKeYRddrtlZXJc73BdL%2Bt5EGGFuIPN4xN9KRNK3dD0oVHSb4bK8WY2Hf75b5wFrig%2BHztrYMdLtsmbwxxGJ9i%2FkqaWeaDfAHjE6A%3D%3D`}
            alt="logo"
            className="logo"
          />

          <div className="nav-bar">
            <nav className="nav-auth-route-user1">
              <ul className="ul-auth-route-user">
                <li className="home">
                  <Link
                    to={"/"}
                    style={{ textDecoration: "none", border: "none" }}
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-items">
                  <Link
                    to={`/collection/${"newReleases"}`}
                    style={{ textDecoration: "none" }}
                  >
                    New Releases
                  </Link>
                </li>

                <li className="nav-items" onClick={handleClickClothingMenu}>
                  <span className="nav-link">Shop Clothing</span>
                </li>

                <li className="nav-items" onClick={handleClickBrandMenu}>
                  <span className="nav-link" style={{ textDecoration: "none" }}>
                    Shop By Brand
                  </span>
                </li>
              </ul>
            </nav>
          </div>

          <Box className="main-icon-box" sx={{ width: { xs: 80, sm: 120 }, }}>
            <BottomNavigation
              sx={{ backgroundColor: "#EFEFEF" }}
              showLabels
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
            >
              <BottomNavigationAction
                sx={{ minWidth: 0, padding: 0, fontSize: 13 }}
                icon={
                  <PersonOutlineOutlined
                    fontSize="medium"
                    sx={{ color: "#1C1C1C", fontWeight: "normal" }}
                    onClick={handleProfileMenuOpen}
                  />
                }
              />

              <BottomNavigationAction
                sx={{ minWidth: 0, padding: 0, fontSize: 13, color: "#1C1C1C" }}
                id="serachIcon"
                icon={<Search fontSize="medium" />}
              />
              <BottomNavigationAction
                onClick={handleCartToggle}
                sx={{ minWidth: 0, padding: 0, fontSize: 13, color: "#1C1C1C" }}
                icon={<LocalMallOutlined fontSize="medium" />}
              />
            </BottomNavigation>
          </Box>
        </div>
      </div>

      {/* Dropdown Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        sx={{ marginTop: "10px" }}
      >
        <MenuItem className="profile-menu-item" onClick={handleProfile}>
          Profile
        </MenuItem>
        <MenuItem className="profile-menu-item" onClick={handleLogout}>
          Logout
        </MenuItem>
      </Menu>

      <Menu
        anchorEl={brandAnchorEl}
        open={openBrand}
        onClose={handleCloseBrandMenu}
        sx={{ marginTop: "10px" }}
      >
        <MenuItem
          className="brand-menu-item"
          onClick={(e) => {
            handleCloseBrandMenu();
            handleReRoute("mango");
          }}
        >
          Mango
        </MenuItem>
        <MenuItem
          className="brand-menu-item"
          onClick={(e) => {
            handleCloseBrandMenu();
            handleReRoute("zara");
          }}
        >
          Zara
        </MenuItem>
        <MenuItem
          className="brand-menu-item"
          onClick={(e) => {
            handleCloseBrandMenu();
            handleReRoute("denim");
          }}
        >
          Denim
        </MenuItem>
        <MenuItem
          className="brand-menu-item"
          onClick={(e) => {
            handleCloseBrandMenu();
            handleReRoute("springField");
          }}
        >
          Spring Field
        </MenuItem>
      </Menu>

      <Menu
        anchorEl={clothingAnchorEl}
        open={openClothing}
        onClose={handleCloseClothingMenu}
        sx={{ marginTop: "10px" }}
      >
        <MenuItem
          className="clothing-menu-item"
          onClick={(e) => {
            handleCloseClothingMenu();
            handleReRoute("man");
          }}
        >
          Man
        </MenuItem>
        <MenuItem
          className="clothing-menu-item"
          onClick={(e) => {
            handleCloseClothingMenu();
            handleReRoute("women");
          }}
        >
          Women{" "}
        </MenuItem>
        <MenuItem
          className="clothing-menu-item"
          onClick={(e) => {
            handleCloseClothingMenu();
            handleReRoute("kid");
          }}
        >
          Kid
        </MenuItem>
      </Menu>
    </>
  );
};

export default MainNavbar;
