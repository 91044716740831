import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Typography,
  InputLabel,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import "./product.css";
import DisplayProductSlider from "../../components/display-product-slider/display-product-slider";
import FeaturedSlider from "../../components/slider-featured/slider-featured";
import AddToCartDrawer from "../../components/add-to-cart/add-to-cart";
import RenderStars from "../../components/render-star/render-star";
import { useDispatch } from "react-redux";
import { addToCart } from "../../redux/slices/cart-slice";

const Product = () => {
  const dispatch = useDispatch();
  const [product, setProduct] = useState({
    productDescription: [],
  });
  const [size, setSize] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [quantity, setQuantity] = useState(1);
  const descriptionRef = useRef(null);
  const [isDescriptionFixed, setIsDescriptionFixed] = useState(false);
  const [activeSection, setActiveSection] = useState("description");
  const [activeIndex, setActiveIndex] = useState(0);
  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    const handleScroll = () => {
      const descriptionTop = descriptionRef.current
        ? descriptionRef.current.getBoundingClientRect().top
        : 0;
      const descriptionHeight = descriptionRef.current
        ? descriptionRef.current.offsetHeight
        : 0;
      const descriptionVisible =
        descriptionTop <= window.innerHeight - descriptionHeight / 13;
      //  console.log("the scroll", window.scrollY)

      if (window.scrollY > 36 && !descriptionVisible) {
        setIsDescriptionFixed(true);
      } else {
        setIsDescriptionFixed(false);
      }
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const availableProduct = {
      mainImage:
        "https://storage.googleapis.com/blog-website-pic-bucket.appspot.com/profileHackathon/postImg-1728397244712-pikaso_texttoimage_A-plain-gray-background-WITH-MAN-WEAR-CLOTHE%20%282%29.jpg?GoogleAccessId=firebase-adminsdk-viejr%40blog-website-pic-bucket.iam.gserviceaccount.com&Expires=2530378800&Signature=aH2fhvEuT5hFn%2BG0%2FD%2FR70fYb9n8vS3PO%2BAsRRwXayruV0htn2r0zHSmysakm4YmteLQdeu7CuQApxrZfvgoavHcKSluRc1uip1hoRUoh4eMbuew%2BBMYkmjsO9UP6FbKpJrDavXqrkzrA7yp3X%2BnojQdxHDlztiGqDsB1Dp9g4%2FbEKoLr5vVQXLYRrw4OX%2BRa6KiqxUgpsuuzwna%2FggARfsaSVr9z0zgmYXILHfXyVjSmdRO42E71Kw2p8NCOUxQohdf8tMrEBei0apeHEOYiBEM8m8zDD8wjzl3G6Z%2FwLG78e%2Bk9KU8mxyFkycOBhN6CXzjAT3k2UWF2mJ%2FABJsnw%3D%3D",
      additionalImage: [
        "https://storage.googleapis.com/blog-website-pic-bucket.appspot.com/profileHackathon/postImg-1728397087242-pikaso_texttoimage_A-plain-gray-background-WITH-MAN-WEAR-CLOTHE%20%281%29.jpg?GoogleAccessId=firebase-adminsdk-viejr%40blog-website-pic-bucket.iam.gserviceaccount.com&Expires=2530378800&Signature=V%2BRG9SukrkJP2cVGZ6A4zsU6shVrej%2BkaDWOrF92iGjehjy8pzjZFhnr0ACuC2scP21QplLwNmVjuIRlCoCnJ1b%2FbMRCv1Ad3CC9GJkk8sjpyKr2wULJ26peFPi%2BKb4YrD3L869KK4vC6tSZI%2BJl9nPmTjkrjbIFLS9C%2BNTtZDQPl5pDoyvWqyCZFl9GsScYQFcA2AxsJ3n8Jsn9iW7AqRbdzWTeWf973HGewAnAQIIZb%2BOZsZRxEWucvH3pUiHvZxMpDMBWWPLWE11r2JEyMIT%2Fop3KvhzCNFh4Lv%2Bb1LU0zfTQOzx3lxmA8o2bz5H6ltFgHb0jyXo7K7deH4Nb1A%3D%3D",
        "https://storage.googleapis.com/blog-website-pic-bucket.appspot.com/profileHackathon/postImg-1728397141801-pikaso_texttoimage_A-plain-gray-background-WITH-MAN-WEAR-CLOTHE.jpg?GoogleAccessId=firebase-adminsdk-viejr%40blog-website-pic-bucket.iam.gserviceaccount.com&Expires=2530378800&Signature=g%2BYlnS3gWKM2AXGiFktkAFv1SwLtyVrwIqKNksMEcCjae2OMnd6Ye0YCtERvlvWSLYHFJcav296SSR7lq%2FMbQrF8N3xBSUFJjhbdiZj2nwMrWz5gq3%2BP2qF2Yg1j9z9cmoC4O5jzbcI%2BVhwjQ5cGVFrDD3MAvhB3rcs%2ByFXHu3SnDIUYm%2F8XmHnX0h9AyV5QaPz8EwAkxY35RgM%2FC6ydgcEBRrHJ5DaAbCbe%2Bq4sbBT8vMU4d4pLgleURtN%2Bjl0WRa%2F7fM6aXsUio8ZJRM5uJRgi98NXYU6Ks7EcAfrB0EkFKkqfZJPHB9kbu6u6NAptrVnToZsw3Kap8otisgL9%2BQ%3D%3D",
        "https://storage.googleapis.com/blog-website-pic-bucket.appspot.com/profileHackathon/postImg-1728397244712-pikaso_texttoimage_A-plain-gray-background-WITH-MAN-WEAR-CLOTHE%20%282%29.jpg?GoogleAccessId=firebase-adminsdk-viejr%40blog-website-pic-bucket.iam.gserviceaccount.com&Expires=2530378800&Signature=aH2fhvEuT5hFn%2BG0%2FD%2FR70fYb9n8vS3PO%2BAsRRwXayruV0htn2r0zHSmysakm4YmteLQdeu7CuQApxrZfvgoavHcKSluRc1uip1hoRUoh4eMbuew%2BBMYkmjsO9UP6FbKpJrDavXqrkzrA7yp3X%2BnojQdxHDlztiGqDsB1Dp9g4%2FbEKoLr5vVQXLYRrw4OX%2BRa6KiqxUgpsuuzwna%2FggARfsaSVr9z0zgmYXILHfXyVjSmdRO42E71Kw2p8NCOUxQohdf8tMrEBei0apeHEOYiBEM8m8zDD8wjzl3G6Z%2FwLG78e%2Bk9KU8mxyFkycOBhN6CXzjAT3k2UWF2mJ%2FABJsnw%3D%3D",
      ],
      alt: "Iced Double Row Ring",
      id: "1HBRW",
      name: "Halo Bezel Ring",
      price: "120",
      rating: 2.5,
      sizes: [
        {
          size: "Small",
          available: true,
        },
        {
          size: "Medium",
          available: false,
        },
        {
          size: "Large",
          available: true,
        },
        {
          size: "X-Large",
          available: false,
        },
      ],
      productDescription: [
        {
          description: {
            material: "Stainless Steel",
            plating: "PVD Vacuum Plating",
            stone: "AAAAA CZ",
            warranty: {
              details:
                "Russet pieces are warranted against manufacturing defect by a 1-year official color warranty from the date of purchase. Manufacturing defects do not include damage resulting from everyday use, or damage sustained after dropping the products or banging it against a hard surface. A defective piece must be registered within 14 days of receiving the product by contacting us: contact@shoprusset.com",
              exclusions: [
                "Normal wear and aging (e.g scratched surfaces)",
                "Damage resulting from improper handling, lack of care, or accidents (knocks, dents, crushing, broken stones, etc.)",
                "Handling of the Russet pieces by non-authorized personnel (e.g for size adjustment, service or repairs)",
              ],
            },
          },
        },
        {
          shippingDetails: [
            "We dispatch our orders directly via our Karachi warehouse located in DHA.",
            "Standard Free Delivery - Trax/Rider (Tracked 4-6 working days).",
            "Express Delivery - TCS Express (Tracked 1-3 working days) please see checkout for cost information.",
          ],
        },
        {
          summary:
            "Measure your wrist/neck in inches with a tape measure. If you do not have one, wrap a strip of paper around your wrist/neck and mark with a pencil where the ends of the paper meet. Use a ruler to measure the flat strip of paper in inches.",
          sizeGuide: {
            ringsSizeGuide: "Rings Size Guide:",
            instructions: [
              "1) Use a string/wire and wrap it around the thickest part of your finger. Cut or hold the pin point and put it to a scale. Measure the size in MM. Refer to the Russet size chart by clicking here.",
              "2) If you have a ring which fits, measure its inner diameter and refer to the Russet size chart by clicking here.",
            ],
          },

          productCare: {
            instructions: [
              "To ensure your jewelry remains looking as new and shiny as possible, we strongly recommend you to follow our aftercare instructions.",
              "316L Stainless Steel jewelry is manufactured to last a lifetime. However, products made from other metals may oxidize over time due to exposure to the air and chemicals.",
              "Here are some tips to keep your precious metals in great condition:",
              "• Polish with polishing cloth.",
              "• Store in its Russet dust bag when not in use.",
              "• Avoid contact with chemicals such as perfumes & deodorant.",
              "• Remove non-stainless jewelry before swimming, showering, and heavy exercise.",
            ],
          },
        },
      ],
    };
    setProduct(availableProduct);
  }, []);
  // console.log('the size', product.sizes);
  const handleChange = (event) => {
    console.log("the size", product);
    setSize(event.target.value);
  };

  const handleIncrement = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const handleAddToCart = () => {
    console.log(
      "the id is ",
      product.id,
      "the name is ",
      product.name,
      "product price is",
      product.price,
      "the quantity",
      quantity,
      "the size is",
      size
    );
    const productDescriptionRedux = {
      id: product.id,
      name: product.name,
      price: product.price,
      quantity: quantity,
      size: size,
      image: product.mainImage,
    };
    dispatch(addToCart(productDescriptionRedux));
    console.log("the user is add some product in add to cart ");
  };
  const handleBuyNow = () => {
    console.log("the user is buy some product");
  };

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <div className="container">
        <Box sx={{ display: "flex" }}>
          <AddToCartDrawer
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
          />
        </Box>

        <Box
          className="container-item container-large"
          style={{
            width: isDescriptionFixed ? "calc(100% - 37%)" : "calc(100% - 37%)",
            transition: "width 0.3s ease",

            marginRight: isDescriptionFixed ? "37%" : "0",
          }}
        >
          <img
            src={product?.mainImage}
            alt={product?.alt}
            style={{ width: "80%", marginBottom: "10px" }}
          />
          {product?.additionalImage?.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`${product?.alt} ${index + 1}`}
              style={{ width: "80%", marginBottom: "10px" }}
            />
          ))}
        </Box>
        <Box className="product-slider">
          <DisplayProductSlider />
        </Box>

        {/* Small Container */}
        <Box
          className="container-item container-small"
          style={{
            position:
              windowWidth <= 1000
                ? "relative"
                : isDescriptionFixed
                ? "fixed"
                : "sticky",
            top: isDescriptionFixed ? "84px" : "0",
            width:
              windowWidth <= 1000 ? "100%" : isDescriptionFixed ? "37%" : "37%",
            height: "100vh",
            right: isDescriptionFixed ? "0" : "initial",
            transition: "width 0.3s ease",
          }}
        >
          <Box className="product-card">
            <Typography variant="p" className="product-name">
              {product?.name}
            </Typography>
            <Box className="product-details">
              <Typography
                sx={{
                  fontFamily: "Montserrat, sans-serif",
                  letterSpacing: "0.09em",

                  fontSize: "1.1rem",
                  color: "#6B6B6B",
                }}
                variant="body1"
              >
                {product?.price} PKR
              </Typography>
              <span style={{ marginRight: 20 }}>
                {RenderStars(product.rating)}
              </span>
            </Box>
          </Box>

          <Box className="product-size-quantity">
            <InputLabel
              id="size-select-label"
              style={{
                color: "black",
                fontFamily: "Montserrat, sans-serif",
                marginBottom: "8px",
              }}
            >
              Size:
            </InputLabel>
            <Select
              labelId="size-select-label"
              id="size-select"
              value={size}
              // label="Size"
              onChange={handleChange}
              sx={{ minWidth: "96%", height: "45px" }}
            >
              {product?.sizes?.length > 0 &&
                product?.sizes?.map((item) => (
                  <MenuItem
                    key={item.size}
                    value={item.size}
                    disabled={!item.available}
                  >
                    {item.available ? item.size : `${item.size} (Out of Stock)`}
                  </MenuItem>
                ))}
            </Select>

            <Box
              className="product-quantity"
              display="flex"
              alignItems="center"
              justifyContent="space-around"
            >
              <IconButton
                sx={{ padding: "4px" }}
                onClick={handleDecrement}
                disabled={quantity <= 1}
              >
                <Remove sx={{ fontSize: "1.2rem" }} />
              </IconButton>
              <Typography
                variant="h6"
                sx={{ margin: "0 10px", fontSize: "1.2rem" }}
              >
                {quantity}
              </Typography>
              <IconButton sx={{ padding: "4px" }} onClick={handleIncrement}>
                <Add sx={{ fontSize: "1.2rem" }} />
              </IconButton>
            </Box>
          </Box>

          <Box className="product-billing">
            <Button
              variant="contained"
              sx={{ backgroundColor: "white", color: "black" }}
              onClick={() => {
                handleAddToCart();
                handleDrawerToggle();
              }}
            >
              Add to Cart
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: "black", color: "white" }}
              onClick={handleBuyNow}
            >
              Buy Now
            </Button>
          </Box>
        </Box>
      </div>
      {windowWidth < 700 ? (
        <div>
          <DisplayDiscriptionForMobile
            product={product}
            Remove={Remove}
            Add={Add}
          />
        </div>
      ) : (
        <Box className="product-description" ref={descriptionRef}>
          <Box className="product-description-bar">
            <p
              className={`${
                activeIndex === 0 ? "active" : ""
              } discription-large-screen`}
              onClick={() => {
                handleSectionChange("description");
                handleClick(0);
              }}
            >
              Description
            </p>
            <p
              className={`${
                activeIndex === 1 ? "active" : ""
              } discription-large-screen`}
              onClick={() => {
                handleSectionChange("shippingDetails");
                handleClick(1);
              }}
            >
              Shipping Details
            </p>
            <p
              className={`${
                activeIndex === 2 ? "active" : ""
              } discription-large-screen`}
              onClick={() => {
                handleSectionChange("sizeGuide");
                handleClick(2);
              }}
            >
              Size Guide and Product Care
            </p>
          </Box>
          <hr
            style={{
              width: "88%",
              margin: "0 auto",
              borderTop: "0.5px solid #C4C4C4",
            }}
          />
          <div className="description-content">
            {activeSection === "description" && (
              <div className="description">
                <div style={{ marginTop: "35px" }}>
                  <p>
                    Material:{" "}
                    {product?.productDescription[0]?.description?.material}
                  </p>
                  <p>
                    Plating:{" "}
                    {product?.productDescription[0]?.description?.plating}
                  </p>
                  <p>
                    Stone: {product?.productDescription[0]?.description?.stone}
                  </p>
                </div>
                <div>
                  <p>Warranty:</p>
                  <p>
                    {
                      product?.productDescription[0]?.description?.warranty
                        ?.details
                    }
                  </p>
                </div>
                <div>
                  <p>Warranty Does Not Cover</p>
                  <ul style={{ marginLeft: "20px" }}>
                    {product?.productDescription[0]?.description?.warranty?.exclusions?.map(
                      (exclusion, index) => (
                        <li key={index}>{exclusion}</li>
                      )
                    )}
                  </ul>
                </div>
              </div>
            )}

            {activeSection === "shippingDetails" && (
              <div className="shipping-details">
                <ul style={{ listStyle: "none" }}>
                  {product?.productDescription[1]?.shippingDetails?.map(
                    (detail, index) => (
                      <li key={index}>{detail}</li>
                    )
                  )}
                </ul>
              </div>
            )}

            {activeSection === "sizeGuide" && (
              <div className="size-guide">
                <div style={{ marginTop: "35px" }}>
                  <p>{product?.productDescription[2]?.summary}</p>
                </div>
                <div>
                  <span>
                    {product?.productDescription[2]?.sizeGuide?.ringsSizeGuide}
                  </span>
                  <ul style={{ listStyle: "none", marginTop: "5px" }}>
                    {product?.productDescription[2]?.sizeGuide?.instructions?.map(
                      (instruction, index) => (
                        <li style={{ marginBottom: "10px" }} key={index}>
                          {instruction}
                        </li>
                      )
                    )}
                  </ul>
                </div>
                <div>
                  <span>Product Care:</span>
                  <ul style={{ listStyle: "none", marginTop: "5px" }}>
                    {product?.productDescription[2]?.productCare?.instructions?.map(
                      (instruction, index) => (
                        <li style={{ marginBottom: "10px" }} key={index}>
                          {instruction}
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </Box>
      )}

      {/* slider of recently viewed */}

      <Box className="slider-box">
        <div className="slider-container-recently-viewed">
          <div className="slider-recently-viewed-heading">
            <p>RECENTLY VIEWED</p>
          </div>

          <FeaturedSlider />
        </div>
      </Box>
    </>
  );
};

export default Product;

// display product for small screen

const DisplayDiscriptionForMobile = ({ product }) => {
  const [displayDiscriptionForMobile, setDisplayDiscriptionForMobile] =
    useState(false);

  const [activeSection, setActiveSection] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);
  const displayDiscriptionMobile = () => {
    setDisplayDiscriptionForMobile(!displayDiscriptionForMobile);
    console.log("the displayDiscriptionForMobile", displayDiscriptionForMobile);
  };
  const handleSectionChange = (section, index) => {
    if (activeSection === section) {
      setActiveSection(null);
      setActiveIndex(null);
    } else {
      setActiveSection(section);
      setActiveIndex(index);
    }
  };
  const handleClick = (index) => {
    setActiveIndex(index);
  };
  return (
    <Box className="product-description-small">
      <Box className="product-description-bar">
        {/* Description Section */}
        <div
          className={`description-div ${
            activeIndex === 0 ? "active-section" : ""
          }`}
        >
          <p
            style={{
              width: "100%",
              marginLeft: "0px",
              borderTop: "1px solid #C4C4C4",
            }}
            className={`flex-container ${
              activeIndex === 0 ? "active" : ""
            } description-tag`}
            onClick={() => {
              handleSectionChange("description", 0);
            }}
          >
            <span className="description-text">Description</span>
            <span className="add-icon">
              {activeIndex === 0 && activeSection === "description" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="12" y1="5" x2="12" y2="19"></line>
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>
              )}
            </span>
          </p>
          {activeSection === "description" && activeIndex === 0 && (
            <div className="description">
              <p>
                Material:{" "}
                {product?.productDescription[0]?.description?.material}
              </p>
              <p>
                Plating: {product?.productDescription[0]?.description?.plating}
              </p>
              <p>Stone: {product?.productDescription[0]?.description?.stone}</p>
              <div>
                <p>Warranty:</p>
                <p>
                  {
                    product?.productDescription[0]?.description?.warranty
                      ?.details
                  }
                </p>
              </div>
              <div>
                <p>Warranty Does Not Cover:</p>
                <ul style={{ marginLeft: "20px" }}>
                  {product?.productDescription[0]?.description?.warranty?.exclusions?.map(
                    (exclusion, index) => (
                      <li key={index}>{exclusion}</li>
                    )
                  )}
                </ul>
              </div>
            </div>
          )}
        </div>

        {/* Shipping Details Section */}
        <div
          className={`shipping-div ${
            activeIndex === 1 ? "active-section" : ""
          }`}
        >
          <p
            style={{ width: "100%", marginLeft: "0px" }}
            className={`flex-container ${
              activeIndex === 1 ? "active" : ""
            } description-tag`}
            onClick={() => {
              handleSectionChange("shippingDetails", 1);
            }}
          >
            <span className="shipping-details-text">Shipping Details</span>
            <span className="add-icon">
              {activeIndex === 1 && activeSection === "shippingDetails" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="12" y1="5" x2="12" y2="19"></line>
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>
              )}
            </span>
          </p>
          {activeSection === "shippingDetails" && activeIndex === 1 && (
            <div className="shipping-details">
              <ul style={{ listStyle: "none" }}>
                {product?.productDescription[1]?.shippingDetails?.map(
                  (detail, index) => (
                    <li key={index}>{detail}</li>
                  )
                )}
              </ul>
            </div>
          )}
        </div>

        {/* Size Guide Section */}
        <div
          className={`size-guide-div ${
            activeIndex === 2 ? "active-section" : ""
          }`}
        >
          <p
            style={{ width: "100%", marginLeft: "0px" }}
            className={`flex-container ${
              activeIndex === 2 ? "active" : ""
            } description-tag`}
            onClick={() => {
              handleSectionChange("sizeGuide", 2);
            }}
          >
            <span className="size-guide-text">Size Guide and Product Care</span>
            <span className="add-icon">
              {activeIndex === 2 && activeSection === "sizeGuide" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="12" y1="5" x2="12" y2="19"></line>
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>
              )}
            </span>
          </p>
          {activeSection === "sizeGuide" && activeIndex === 2 && (
            <div className="size-guide">
              <p className="size-guide-summary">
                {product?.productDescription[2]?.summary}
              </p>
              <span>
                {product?.productDescription[2]?.sizeGuide?.ringsSizeGuide}
              </span>
              <ul style={{ listStyle: "none", marginTop: "5px" }}>
                {product?.productDescription[2]?.sizeGuide?.instructions?.map(
                  (instruction, index) => (
                    <li style={{ marginBottom: "10px" }} key={index}>
                      {instruction}
                    </li>
                  )
                )}
              </ul>
              <div>
                <span>Product Care:</span>
                <ul style={{ listStyle: "none", marginTop: "5px" }}>
                  {product?.productDescription[2]?.productCare?.instructions?.map(
                    (instruction, index) => (
                      <li style={{ marginBottom: "10px" }} key={index}>
                        {instruction}
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
          )}
        </div>
      </Box>
    </Box>
  );
};
