// FilterDrawer.js
import React, { useState } from "react";
import {
  Drawer,
  Collapse,
  Divider,
  Switch,
  Slider,
  Button,
} from "@mui/material";
import {
  CloseOutlined,
  ExpandMoreOutlined,
  ExpandLessOutlined,
} from "@mui/icons-material";
import "./filter-drawer.css";

const drawerWidth = 340;

const FilterDrawer = ({
  mobileOpen,
  handleDrawerToggle,
  isExpanded,
  handleToggleAvailability,
  isExpandedPrice,
  handleTogglePrice,
  priceRange,
  handlePriceChange,
  getProductFilter,
}) => {
  const [availability, setAvailability] = useState(false);
  const [priceMin, setPriceMin] = useState("0");
  const [priceMax, setPriceMax] = useState("5000");
  const drawer = (
    <div
      style={{
        marginTop: 0,
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <div>
        <div className="heading-drawer">
          <p>FILTERS</p>
          <CloseOutlined
            style={{ marginRight: "20px" }}
            onClick={handleDrawerToggle}
          />
        </div>
        <Divider style={{ marginTop: 22 }} />

        <div className="drawer-section" onClick={handleToggleAvailability}>
          <p>AVAILABILITY</p>
          {isExpanded ? (
            <ExpandLessOutlined
              fontSize="small"
              style={{ marginRight: "20px" }}
            />
          ) : (
            <ExpandMoreOutlined
              fontSize="small"
              style={{ marginRight: "20px" }}
            />
          )}
        </div>

        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          <div className="switch-container">
            <Switch
              size="small"
              defaultChecked={false}
              onClick={(event) => setAvailability(event.target.checked)}
              sx={{
                width: 45,
                "& .MuiSwitch-track": {
                  backgroundColor: "black",
                  "&:hover": { backgroundColor: "black" },
                },
                "& .MuiSwitch-thumb": {
                  color: "white",
                  "&:hover": { backgroundColor: "white" },
                },
              }}
            />
            <p>In stock only</p>
          </div>
        </Collapse>

        <div style={{ marginTop: 22 }}>
          <Divider style={{ width: "92%", margin: "0 auto" }} />
        </div>

        <div className="drawer-section" onClick={handleTogglePrice}>
          <p>Price</p>
          {isExpandedPrice ? (
            <ExpandLessOutlined
              fontSize="small"
              style={{ marginRight: "20px" }}
            />
          ) : (
            <ExpandMoreOutlined
              fontSize="small"
              style={{ marginRight: "20px" }}
            />
          )}
        </div>

        <Collapse in={isExpandedPrice} timeout="auto" unmountOnExit>
          <div style={{ padding: "5px 22px" }}>
            <Slider
              value={priceRange}
              onChange={handlePriceChange}
              onChangeCommitted={(event, newValue) => {
                setPriceMin(newValue[0]);
                setPriceMax(newValue[1]);
              }}
              valueLabelDisplay="auto"
              min={0}
              max={5000}
              sx={{
                "& .MuiSlider-track": {
                  backgroundColor: "black",
                  color: "black",
                },
                "& .MuiSlider-rail": { backgroundColor: "gray" },
                "& .MuiSlider-thumb": { color: "black", width: 13, height: 13 },
              }}
            />
            <div className="price-display">
              <div>RsPKR: {priceRange[0]}</div>
              <div>RsPKR: {priceRange[1]}</div>
            </div>
          </div>
        </Collapse>
      </div>

      <div style={{ flexGrow: 1 }}></div>

      <div
        className="submit-button-container"
        style={{
          textAlign: "center",
          padding: "30px 0",
          borderTop: "1px solid #d4d4d4",
        }}
      >
        <Button
          onClick={(event) =>
            getProductFilter(availability, priceMin, priceMax)
          }
          variant="contained"
          color="primary"
          sx={{
            backgroundColor: "black",
            width: "90%",
            "@media (max-width: 600px)": { width: "95%" },
          }}
        >
          Submit
        </Button>
      </div>
    </div>
  );

  return (
    <Drawer
      variant="temporary"
      open={mobileOpen}
      anchor="right"
      onClose={handleDrawerToggle}
      sx={{
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          backgroundColor: "white",
          zIndex: 1300,
        },
      }}
    >
      {drawer}
    </Drawer>
  );
};

export default FilterDrawer;
