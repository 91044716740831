import React, { useEffect, useRef, useState } from "react";
import { Box, Container, Button, Typography } from "@mui/material";
import "./home.css";
import videoSrc from "../../assets/main-video.mp4";
import posterImage from "../../assets/poster-image.PNG";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import FeaturedSlider from "../../components/slider-featured/slider-featured";

const Home = () => {
  const [isVisible, setIsVisible] = useState(false);
  const imgRef = useRef(null);
  const userState = useSelector((state) => state.user.isLogin);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("the user info", userState);
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, []);

  const handleReRoute = (value) => {
    console.log(value);
    navigate(`/collection/${value}`);
  };


const Brands = [
  "mango",
  "denim",
  "zara",
  "springField",
  "mango",
  "denim",
  "zara",
  "springField",
];

  return (
    <>
      <Container
        maxWidth={false}
        disableGutters
        sx={{ height: "110vh", width: "100%" }}
      >
        <Box className="home-video-container">
          <video
            autoPlay
            playsInline
            muted
            loop
            preload="metadata"
            poster={posterImage}
            style={{
              position: "absolute",
              top: 120,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              zIndex: 0,
            }}
          >
            <source src={videoSrc} type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          <Button
            variant="contained"
            disableElevation
            className="shop-new-release-button"
            sx={{
              textTransform: "none",
              backgroundColor: "#FFFFFF",
              color: "#161616",
              padding: "15px 25px",
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              zIndex: "1",
              fontWeight: "500",
              borderRadius: "7px",
            }}
            onClick={() => handleReRoute("newReleases")}
          >
            Shop New Releases
          </Button>
        </Box>
      </Container>




<div className="slider-container">
  <div className="slider-track"

    >
    <div className="slider-item" onClick={() => handleReRoute("mango")}>
      Mango
    </div>
    <div className="slider-item" onClick={() => handleReRoute("denim")}>
      Denim
    </div>
    <div className="slider-item" onClick={() => handleReRoute("zara")}>
      Zara
    </div>
    <div className="slider-item" onClick={() => handleReRoute("springField")}>
      Spring Field
    </div>
  
    <div className="slider-item" onClick={() => handleReRoute("mango")}>
      Mango
    </div>
    <div className="slider-item" onClick={() => handleReRoute("denim")}>
      Denim
    </div>
    <div className="slider-item" onClick={() => handleReRoute("zara")}>
      Zara
    </div>
    <div className="slider-item" onClick={() => handleReRoute("springField")}>
      Spring Field
    </div>
    </div>
</div>

      <div class="image-container">
        <a href="#">
          <img
            // src="https://storage.googleapis.com/blog-website-pic-bucket.appspot.com/profileHackathon/postImg-1728397244712-pikaso_texttoimage_A-plain-gray-background-WITH-MAN-WEAR-CLOTHE%20%282%29.jpg?GoogleAccessId=firebase-adminsdk-viejr%40blog-website-pic-bucket.iam.gserviceaccount.com&Expires=2530378800&Signature=aH2fhvEuT5hFn%2BG0%2FD%2FR70fYb9n8vS3PO%2BAsRRwXayruV0htn2r0zHSmysakm4YmteLQdeu7CuQApxrZfvgoavHcKSluRc1uip1hoRUoh4eMbuew%2BBMYkmjsO9UP6FbKpJrDavXqrkzrA7yp3X%2BnojQdxHDlztiGqDsB1Dp9g4%2FbEKoLr5vVQXLYRrw4OX%2BRa6KiqxUgpsuuzwna%2FggARfsaSVr9z0zgmYXILHfXyVjSmdRO42E71Kw2p8NCOUxQohdf8tMrEBei0apeHEOYiBEM8m8zDD8wjzl3G6Z%2FwLG78e%2Bk9KU8mxyFkycOBhN6CXzjAT3k2UWF2mJ%2FABJsnw%3D%3D"
           src="https://img.freepik.com/free-photo/proud-businessman-with-tablet_23-2147641243.jpg?t=st=1729352576~exp=1729356176~hmac=bf473254bc13e2a45ca90a31dabb491a611ab5f8633c06af1917d18471f5dfd1&w=360"
            alt=""
            width="1365"
            height="2048"
            loading="eager"
            sizes="(max-width: 699px) 100vw, (max-width: 1149px) 50vw, 33vw"
            class="zoom-image group-hover:zoom"
            draggable="false"
            object-fit="cover"
          
            onClick={() => handleReRoute("man")}
          />
        </a>

        <a href="#">
          <img
            src={`https://storage.googleapis.com/blog-website-pic-bucket.appspot.com/profileHackathon/postImg-1728381938319-girl3.avif?GoogleAccessId=firebase-adminsdk-viejr%40blog-website-pic-bucket.iam.gserviceaccount.com&Expires=2530378800&Signature=SrSZTQudgTgDOhP%2BqLi0ruJdoohEJb0oGTgxGknlBH1uqbk5%2FM4oU5FNwYD5BdTs%2BiDsOit5ZXGzw6tBuZsEAmzxe3RpqvKr3hvR9ZdKRRNjl7bzzT%2FM46YHiEA%2F6L1dwYG2vOk0ymFWAV%2BfzS%2F%2FAxiNzsi6OdkUuVmjlfqVutMaVKB43tdlLZ71SYyuX3TOXUssS%2B%2FF7UIbyo9jF2XFhe1mNtBwvmwDBJZf7eL%2F4WPKo7qsBfvyHmfDKodex8zPXgR7R7GK7yhVnAXrdGe43RBSWvpRwy3EPXDoIO%2B9FbTvBVD%2BwSHt9Jsp6mB1QjGMW3eYKjVRrcvN0U%2Fpe4wV6A%3D%3D`}
            alt=""
            width="1365"
            height="2048"
            loading="eager"
            sizes="(max-width: 699px) 100vw, (max-width: 1149px) 50vw, 33vw"
            class="zoom-image group-hover:zoom"
            draggable="false"
            onClick={() => handleReRoute("women")}
            style={{ objectFit: "cover", width: "100%", height: "100%" }}
          />
        </a>

        <a href="#">
          <img
            src={`https://storage.googleapis.com/blog-website-pic-bucket.appspot.com/profileHackathon/postImg-1728382316230-kids.avif?GoogleAccessId=firebase-adminsdk-viejr%40blog-website-pic-bucket.iam.gserviceaccount.com&Expires=2530378800&Signature=llAAxsCCR0Knk71xcmMcAMm8SSLoMjXtO4LY%2FBZwB2HOF%2BQsWOfoSA8eeH3oq9DNrhsLUK3cMNyfWE4UiyF8MR%2F1Ra0X0VvnUpa6%2Fkoqwcc%2F08aWxbuHEVQx%2FT1I6diF86r%2BzShjuP98NGWD186GagNalIBH6Pe0g%2FW2NJDk%2F3YfSdDyWT0nnMCDQjHAfjlhu3fdvtFY9VNKD8Hym0XDWxo941GKQM0ePOQQ1dK5MmCVYwiQWLA%2Ft8HLJQAv%2F5YD6IpLg4s70F7UwgLz70HtG8yjyi7CGSSE6GhcM9Kytr5x8VtFw1gHIJPtyRYx54A8IiId4rMURUJSCZ6Is9r%2Bsg%3D%3D`}
            alt=""
            width="1365"
            height="2048"
            loading="eager"
            sizes="(max-width: 699px) 100vw, (max-width: 1149px) 50vw, 33vw"
            class="zoom-image group-hover:zoom"
            draggable="false"
            onClick={() => handleReRoute("kid")}
          />
        </a>
      </div>

      {/* Featured Collection */}
      <div className="main-featured-container">
        <div className="slider-container-featured">
          <div className="slider-featured-heading">
            <p className="featured-heading">Featured Collection</p>
            <div
              className="collection-heading-container"
              style={{
                backgroundColor: "rgb(239, 239, 239)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <svg
                width="30"
                height="32"
                style={{
                  borderRadius: "50%",
                  background: "rgb(239, 239, 239)",
                  border: "1px solid #1C1C1C",
                  marginRight: "10px",
                }}
              >
                <circle
                  cx="15"
                  cy="16"
                  r="12"
                  fill="rgb(239, 239, 239)"
                  style={{ border: "1px solid #1C1C1C" }}
                />
                <line
                  x1="8"
                  y1="5"
                  x2="22"
                  y2="25"
                  stroke="#1C1C1C"
                  strokeWidth="2"
                />
                <line
                  x1="22"
                  y1="5"
                  x2="8"
                  y2="25"
                  stroke="#1C1C1C"
                  strokeWidth="2"
                />
              </svg>
              <p className="collection-heading">Collection</p>
            </div>
          </div>
          <FeaturedSlider />
        </div>
        <Box sx={{ marginTop: "60px" }} textAlign="center">
          <Button
          onClick={() => handleReRoute("all items")}
            variant="contained"
            disableElevation
            sx={{
              textTransform: "none",
              letterSpacing: "1px",
              backgroundColor: "#1C1C1C",
              color: "#FFFFFF",
              padding: "10px 29px",
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              zIndex: "1",
              fontWeight: "500",
              borderRadius: "7px",
            }}
          >
            view all
          </Button>
        </Box>
      </div>

      <Box className="fullscreen-image">
        <img
          ref={imgRef}
          src="https://storage.googleapis.com/blog-website-pic-bucket.appspot.com/profileHackathon/postImg-1728398888691-pikaso_texttoimage_A-plain-gray-background-WITH-MAN-WEAR-CLOTHE-CROP.jpg?GoogleAccessId=firebase-adminsdk-viejr%40blog-website-pic-bucket.iam.gserviceaccount.com&Expires=2530378800&Signature=K8VboAgONN68Cw74ItvTTARhSgYKYK3zgr3escRuJDvnyXH107fdQPlDCi2Hyaq699BkfDxLA6Ohl9ZZxBZhdget%2BNHANqSTTDZNfjdwrv9RntOKRapU3JHSGkRZnxRD3bUXgbfCqRYbiXz2RkG%2FOxNy6ZWlLP8%2BClAJ9e4utH%2BnHZCFsbA0tXsyO1kDt7JWOzW%2Bz3FHAthhrt%2FJP0a9B%2Fi%2BTrGIgnVbyLnCbnHSE3mO3h5HJHg%2FLQ%2BE5kjyvfWGWBf0YV7nass%2Fvk3FGgkhVH2Z2P6oGOhprEq1kFVy3L3er2oDspqBZegYqqnviutdWzL3ey0GS0N7ykHvN%2FNk5A%3D%3D"
          alt="Product"
          loading="lazy"
          className={isVisible ? "visible" : ""}
        />
        <Box className="overlay-text">
          <Typography variant="p" sx={{ fontSize: "11px", fontWeight: "500" }}>
            Just Landed
          </Typography>
          <Typography variant="p" sx={{ fontSize: "17px", fontWeight: "500" }}>
            Initial pandent
          </Typography>
          <Button
            variant="contained"
            disableElevation
            onClick={() => navigate("/product/cop")}
            sx={{
              textTransform: "none",
              letterSpacing: "1px",
              backgroundColor: "#FFFFFF",
              color: "#1C1C1C",
              padding: "12px 29px",
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              zIndex: "1",
              fontWeight: "500",
              borderRadius: "7px",
            }}
          >
            cop now
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Home;
