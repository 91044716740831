import React, { useEffect } from "react";
import { Box, Container, Button, Typography } from "@mui/material";
import './otp.css';
import axios from "axios";
import { baseUrl } from "../../core";
import { Formik, Form, Field } from "formik";
import CustomizedSnackbars from "../../components/toaster/toaster";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";


const Login = () =>{
    const navigate = useNavigate();
    const [toast, setToast] = React.useState(false);
    const [userEmail, setUserEmail] = React.useState("");
    const [errorResponse, setErrorResponse] = React.useState("");

   useEffect(() => {
       const email = localStorage.getItem('email');  
       setUserEmail(email)

   },[])
    const returnHome = () => {
        navigate("/");
      };
    
    const handleSubmit =async (values,    resetForm)=>{
        console.log("the code is ", values);
        try{
            const newValues = {...values, email: userEmail}
            const response = await axios.post(
                `${baseUrl}/v1/auth/validate-otp`, 
                newValues)
                resetForm(); 
            console.log("the login response", response);
            if(response.data.message === "OTP verified successfully"){
          navigate('/')
            }
            else{
                setToast(true)
                setErrorResponse(response.data.message)
            }

        }
        catch(err){
            console.log("Please try again later");
            setToast(true)
             
               setErrorResponse("Please try again later")
        }
    }
    return (
       <>
       <Box className= "otp-container">
        <Box className="logo-otp-container">
<img
onClick={returnHome}
 src={`https://storage.googleapis.com/blog-website-pic-bucket.appspot.com/profileHackathon/postImg-1728384181725-logo-pic.png?GoogleAccessId=firebase-adminsdk-viejr%40blog-website-pic-bucket.iam.gserviceaccount.com&Expires=2530378800&Signature=mHra4DsXsuZYF%2FSloNbpDQLUqgNopfcSMnWl5t%2Ff%2BW3VJg8EqQhhSky0CY%2FzCnaqTnp6xJe1clrs4yfzlGPaDvvKUQXxJLIxZJ%2BOxkNjlPgl7SlGg%2FEnP12ryEeabGb4eLzlVX0TFQXZmWwTbuD4R3PYrNA8tasDzk66hfZyKVPK4ndT1jqqcIVzvo12JsbHUNZzgRAmlJbPy%2BuqLcAlCffIcjVBDk%2BdjmOX2h75wzaDV1eo65AKeYRddrtlZXJc73BdL%2Bt5EGGFuIPN4xN9KRNK3dD0oVHSb4bK8WY2Hf75b5wFrig%2BHztrYMdLtsmbwxxGJ9i%2FkqaWeaDfAHjE6A%3D%3D`}
alt="logo"
className="logo-login"
/>
      

        </Box>
        <Box className="form-otp-container">
            <Box className="form-otp-title">
                <h3>Enter Code</h3>
                <p>Sent to {userEmail}</p>
                </Box>
<Formik

initialValues={{
    otp: "",
 
}}
validationSchema={Yup.object({
    otp: Yup.string()
    .matches(/^\d{6}$/, "Must be a 6 digit number")
    .required("Required"),

})}
onSubmit={(values, { setSubmitting, resetForm }) => {
    handleSubmit(values, resetForm);
  setTimeout(() => {
    // alert(JSON.stringify(values, null, 2));
    setSubmitting(false);
  }, 400);
}}
>
{({ isSubmitting }) => (
  <Form className="otp-form">
    <Box className="input-otp-container">   
    <Field
      type="otp"
      name="otp"
      placeholder="6-digit Code"
      className="input-otp"
    />
    </Box>
    <Button
    type="submit"
    variant="contained"
    className="submit-button-otp"
    disabled={isSubmitting}
    >
      Continue
    </Button>
</Form>
)}
</Formik>
</Box>
       </Box>
{toast && <CustomizedSnackbars errorResponse={errorResponse} />}

       </>
    )
}

export default Login