import { configureStore } from '@reduxjs/toolkit';
import cartReducer from './slices/cart-slice';
import userReducer from './slices/user-slice'

// Function to save state to local storage
const saveToLocalStorage = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('cart', serializedState);
    } catch (e) {
        console.error('Could not save state', e);
    }
};

// Function to load state from local storage
const loadFromLocalStorage = () => {
    try {
        const serializedState = localStorage.getItem('cart');
        if (serializedState === null) return undefined; // No saved state
        return JSON.parse(serializedState);
    } catch (e) {
        console.error('Could not load state', e);
        return undefined;
    }
};

// Load cart state from local storage if available
const persistedState = loadFromLocalStorage();

const store = configureStore({
    reducer: {
        cart: cartReducer,
        user: userReducer
    },
    preloadedState: {
        cart: persistedState, // Load persisted cart items

    },
});

// Subscribe to store updates and save the cart state to local storage
store.subscribe(() => {
    saveToLocalStorage(store.getState().cart);
});

export default store;