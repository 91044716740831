// // const isLocalhost =window.location.href.includes("localhost")
// const isProduction = window.location.href.includes("https")

// export const baseUrl = isProduction ? "" : "http://localhost:5000";


// const isLocalhost = window.location.href.includes("localhost");
// const isProduction = window.location.protocol === "https:" && window.location.hostname === "wokegarments.com";

// // Define baseUrl based on the environment
// export const baseUrl = isLocalhost
//   ? "http://localhost:5000"  // Local development
//   : isProduction
//   ? "https://api.wokegarments.com"  // Production API URL with /v1 path
//   : "http://localhost:5000";  // Fallback


const isLocalhost = window.location.href.includes("localhost");
const isProduction = window.location.protocol === "https:" && window.location.hostname === "wokegarments.com";

// Define baseUrl based on the environment
export const baseUrl = isLocalhost
  ? "http://localhost:5000"  // Local development
  : isProduction
  ? "https://woaksgarment-api.vercel.app"  // Production API URL
  : "https://woaksgarment-api.vercel.app";  