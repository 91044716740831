import react, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./slider-featured.css";
import RenderStars from "../render-star/render-star";
import getCookie from "../../utlilityFunctions/getAcessToken";
import axios from "axios";
import { baseUrl } from "../../core";
import { useNavigate } from "react-router-dom";

const FeaturedSlider = () => {
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getProduct();
  }, []);

  const showProductDescription = (sku) => {
    navigate(`/product/${sku}`);
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 4,
      slidesToSlide: 2,
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const getProduct = async (
    availability = null,
    minPrice = null,
    maxPrice = null,
    category = null,
    brand = null
  ) => {
    try {
      const token = getCookie("accessToken");
      console.log("the token", token);
      const response = await axios.get(`${baseUrl}/v1/product`, {
        params: {
          availability,
          minPrice,
          maxPrice,
          category,
          brand,
        },
        headers: {
          // Authorization: `Bearer ${token}`,

          "Content-Type": "application/json",
        },
      });
      const products = response.data.products.slice(0, 5);
      setFeaturedProducts(products);
      console.log("the product ", response.data.products);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Carousel
        keyBoardControl={true}
        transitionDuration={500}
        partialVisible={false}
        itemClass="carousel-item-padding-40-px"
        containerClass="carousel-container"
        responsive={responsive}
      >
        {featuredProducts.map((item) => (
          <div
            className="card-featured"
            key={item.src}
            onClick={() => showProductDescription(item.sku)}
          >
            <img src={item.src} alt={item.alt} />

            <div className="product-info-featured">
              <p
                className="product-name"
                style={{
                  fontSize: "calc(0.75rem* 1)",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                {item.name}
              </p>
              <p className="product-price">{item.price}</p>
              {RenderStars(item.rating)}
            </div>
          </div>
        ))}
      </Carousel>
    </>
  );
};
export default FeaturedSlider;
