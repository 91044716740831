import React from "react";
import "react-multi-carousel/lib/styles.css";
import "./display-products.css";
import { Box } from "@mui/material";

const DisplayProducts = ({ filterProducts }) => {
  return (
    <>
      <>
        {filterProducts.map((product, index) => (
          <Box key={index} className="products">
            <img
              src={product.src}
              alt={product.alt}
              style={{ width: "100%", height: "auto" }}
            />

            <div className="product-info">
              <p>{product.name}</p>

              <p>{product.price}</p>
            </div>
          </Box>
        ))}
      </>
    </>
  );
};

export default DisplayProducts;
