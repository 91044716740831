import React, { useState } from 'react';
import { Drawer, List,  Button, Box, IconButton, Typography  } from '@mui/material';
import {  Add, Remove, FiberManualRecordSharp } from '@mui/icons-material';
import { CloseOutlined } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'; 
import { incrementQuantity, decrementQuantity, removeFromCart } from '../../redux/slices/cart-slice';
import './add-to-cart.css'; 

const drawerWidth = {
  xs: '85vw', // 85% of the viewport width on small screens
  sm: '450px', // 450px width on larger screens
};

const AddToCartDrawer = ({ mobileOpen, handleDrawerToggle }) => {
  const cartItems = useSelector((state) => state.cart.items);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState('');


    


  const handleIncrement = (itemId) => {
    dispatch(incrementQuantity(itemId)); // Dispatch the increment action
  };

  const handleDecrement = (itemId) => {
    dispatch(decrementQuantity(itemId)); // Dispatch the decrement action
  };

  const handleRemoveItem = (itemId) => {
    console.log('Item ID:', itemId);
    dispatch(removeFromCart(itemId));
  }

  const proceedToBuy = () =>{
    navigate('/checkout');
  }
  console.log("the moile open", mobileOpen);

  const mainDrawer = (
    <div className="drawer-container-cart">
      {/* Drawer Header */}
      <div className="heading-drawer-cart">
        <span>CART</span>
        <CloseOutlined
          style={{ marginRight: '20px', cursor: 'pointer' }}
          onClick={handleDrawerToggle}
        />
      </div>

      {/* Cart Items Section (Scrollable) */}
      <div className="cart-items-container">
        {cartItems.length > 0 ? (
          <List>
            {cartItems.map((item, index) => (
              <div key={index}>
                  <div className="cart-item">
                  {/* Product Image */}
                  <img
                    src={item.image}
                    // alt={item.name}
                    className="cart-item-image"
                  />
                  {/* Product Details */}
                  <div className="cart-item-details">
                    <div className="item-name">{item.name}</div>
                    <div className="item-price">{item.price} PKR</div>
                    <div className="item-size">{'normal'}</div>
                    <div className="item-quantity">
                    <Box className="product-quantity-cart" display="flex" alignItems="center" justifyContent="space-around">
              <IconButton sx={{ padding: '4px' }} onClick={() => handleDecrement(item.id)} disabled={item.quantity <= 1}>
                <Remove sx={{ fontSize: '1.2rem' }} />
              </IconButton>
              <Typography variant="body1" className='item-quantity'>
                {item.quantity}
              </Typography>
              <IconButton sx={{ padding: '4px' }} onClick={() => handleIncrement(item.id)}>
                <Add sx={{ fontSize: '1.2rem' }} />
              </IconButton>
            </Box>
            <div className="remove-individual-item-button" onClick={() => handleRemoveItem(item.id)}>Remove</div>
                </div>
                  </div>
                  </div>
               
              </div>
            ))}
          </List>
        ) : (
          <div className="empty-cart-message">Your cart is empty</div>
        )}
      </div>

      {/* Buy Now Button (Fixed at the bottom) */}
      <div  className={`buy-now-button`}>    
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={proceedToBuy}
        >
          <div>
            <span>Checkout</span>
            <span><FiberManualRecordSharp style={{ fontSize: '7px' }} fontSize='small' /></span>
            <span>{cartItems.reduce((total, item) => total + item.price * item.quantity, 0)} PKR</span>
          </div>
        </Button>
      </div>
    </div>
  );

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      sx={{
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          backgroundColor: 'white',
          zIndex: 1300,
        },
      }}
    >
      {mainDrawer}
    </Drawer>
  );
};

export default AddToCartDrawer;
