import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './display-product-slider.css';

const DisplayProductSlider = () => {
  const responsive = {
    tablet: {
      breakpoint: { max: 1000, min: 0 },
      items: 1,
    },
  };

  const productData = [
    {
      src: '//shoprusset.com/cdn/shop/files/IMG_8328.jpg?v=1724916739',
      alt: 'Iced Double Row Ring',
      name: 'Iced Double Row Ring',
      price: '$120',
      rating: 2.5,
    },
    {
      src: '//shoprusset.com/cdn/shop/files/IMG_8330.jpg?v=1725970442',
      alt: 'Stardust Inlay Tungsten Ring',
      name: 'Stardust Inlay Tungsten Ring',
      price: '$140',
      rating: 4.2,
    },
    {
      src: '//shoprusset.com/cdn/shop/products/MG_0434-min.jpg?v=1672233419&amp;width=3639',
      alt: '3MM Tennis Chain',
      name: '3MM Tennis Chain',
      price: '$90',
      rating: 4.4,
    },
    {
      src: '//shoprusset.com/cdn/shop/files/IMG_8410.jpg?v=1724916526&amp;width=1907',
      alt: 'Elysian Crest Ring',
      name: 'Elysian Crest Ring',
      price: '$180',
      rating: 4.8,
    },
    {
      src: '//shoprusset.com/cdn/shop/files/IMG_8413.jpg?v=1724916647&amp;width=1871',
      alt: 'Halo Bezel Ring',
      name: 'Halo Bezel Ring',
      price: '$200',
      rating: 4.7,
    },
    {
      src: '//shoprusset.com/cdn/shop/files/IMG_8330.jpg?v=1725970442&amp;width=1905',
      alt: 'Stardust Inlay Tungsten Ring',
      name: 'Stardust Inlay Tungsten Ring',
      price: '$140',
      rating: 4.2,
    },
   
  ];
  return (
    <>
      <Carousel
        responsive={responsive}
        swipeable={true}
        draggable={true}
        showDots={true}
        infinite={true}
        ssr={true}
        keyBoardControl={true}
        transitionDuration={500}
        partialVisible={false}
        itemClass="carousel-item-padding-40-px"
        containerClass="carousel-container"
        // focusOnSelect={true}
      >
        {productData.map((item) => (
          <div className="card" key={item.src}>
            <a href="#">
              <img src={item.src} alt={item.alt} />
            </a>
          </div>
        ))}
      </Carousel>
    </>
  );
};
export default DisplayProductSlider;
